/**
 * Implement Gatsby's Browser APIs in this file.
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 */

module.exports.disableCorePrefetching = () => true;

module.exports.onClientEntry = () => {
  require('./src/theme/theme.scss');

  window.__TAE__ = (typeof __TAE__ === 'object') ? window.__TAE__ : {}; // eslint-disable-line
};
