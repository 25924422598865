// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-html-index-jsx": () => import("./../../../src/pages/about-us.html/index.jsx" /* webpackChunkName: "component---src-pages-about-us-html-index-jsx" */),
  "component---src-pages-academic-writing-service-html-index-jsx": () => import("./../../../src/pages/academic-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-academic-writing-service-html-index-jsx" */),
  "component---src-pages-admission-writing-service-html-index-jsx": () => import("./../../../src/pages/admission-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-admission-writing-service-html-index-jsx" */),
  "component---src-pages-application-writing-service-html-index-jsx": () => import("./../../../src/pages/application-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-application-writing-service-html-index-jsx" */),
  "component---src-pages-argumentative-writing-service-html-index-jsx": () => import("./../../../src/pages/argumentative-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-argumentative-writing-service-html-index-jsx" */),
  "component---src-pages-arts-writing-service-html-index-jsx": () => import("./../../../src/pages/arts-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-arts-writing-service-html-index-jsx" */),
  "component---src-pages-biology-writing-service-html-index-jsx": () => import("./../../../src/pages/biology-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-biology-writing-service-html-index-jsx" */),
  "component---src-pages-business-writing-service-html-index-jsx": () => import("./../../../src/pages/business-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-business-writing-service-html-index-jsx" */),
  "component---src-pages-buy-admission-html-index-jsx": () => import("./../../../src/pages/buy-admission.html/index.jsx" /* webpackChunkName: "component---src-pages-buy-admission-html-index-jsx" */),
  "component---src-pages-buy-college-html-index-jsx": () => import("./../../../src/pages/buy-college.html/index.jsx" /* webpackChunkName: "component---src-pages-buy-college-html-index-jsx" */),
  "component---src-pages-buy-online-html-index-jsx": () => import("./../../../src/pages/buy-online.html/index.jsx" /* webpackChunkName: "component---src-pages-buy-online-html-index-jsx" */),
  "component---src-pages-buy-personal-statement-html-index-jsx": () => import("./../../../src/pages/buy-personal-statement.html/index.jsx" /* webpackChunkName: "component---src-pages-buy-personal-statement-html-index-jsx" */),
  "component---src-pages-cheap-writing-service-html-index-jsx": () => import("./../../../src/pages/cheap-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-cheap-writing-service-html-index-jsx" */),
  "component---src-pages-college-writing-service-html-index-jsx": () => import("./../../../src/pages/college-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-college-writing-service-html-index-jsx" */),
  "component---src-pages-cookie-policy-html-index-jsx": () => import("./../../../src/pages/cookie-policy.html/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-html-index-jsx" */),
  "component---src-pages-critical-writing-service-html-index-jsx": () => import("./../../../src/pages/critical-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-critical-writing-service-html-index-jsx" */),
  "component---src-pages-custom-writing-service-html-index-jsx": () => import("./../../../src/pages/custom-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-custom-writing-service-html-index-jsx" */),
  "component---src-pages-dashboard-index-jsx": () => import("./../../../src/pages/dashboard/index.jsx" /* webpackChunkName: "component---src-pages-dashboard-index-jsx" */),
  "component---src-pages-economics-writing-service-html-index-jsx": () => import("./../../../src/pages/economics-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-economics-writing-service-html-index-jsx" */),
  "component---src-pages-english-writing-service-html-index-jsx": () => import("./../../../src/pages/english-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-english-writing-service-html-index-jsx" */),
  "component---src-pages-for-sale-html-index-jsx": () => import("./../../../src/pages/for-sale.html/index.jsx" /* webpackChunkName: "component---src-pages-for-sale-html-index-jsx" */),
  "component---src-pages-frequently-asked-questions-html-index-jsx": () => import("./../../../src/pages/frequently-asked-questions.html/index.jsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-html-index-jsx" */),
  "component---src-pages-guarantees-html-index-jsx": () => import("./../../../src/pages/guarantees.html/index.jsx" /* webpackChunkName: "component---src-pages-guarantees-html-index-jsx" */),
  "component---src-pages-help-services-html-index-jsx": () => import("./../../../src/pages/help-services.html/index.jsx" /* webpackChunkName: "component---src-pages-help-services-html-index-jsx" */),
  "component---src-pages-high-school-writing-service-html-index-jsx": () => import("./../../../src/pages/high-school-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-high-school-writing-service-html-index-jsx" */),
  "component---src-pages-history-writing-service-html-index-jsx": () => import("./../../../src/pages/history-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-history-writing-service-html-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-law-writing-service-html-index-jsx": () => import("./../../../src/pages/law-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-law-writing-service-html-index-jsx" */),
  "component---src-pages-legit-writing-service-html-index-jsx": () => import("./../../../src/pages/legit-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-legit-writing-service-html-index-jsx" */),
  "component---src-pages-magic-link-from-your-classmate-index-jsx": () => import("./../../../src/pages/magic-link-from-your-classmate/index.jsx" /* webpackChunkName: "component---src-pages-magic-link-from-your-classmate-index-jsx" */),
  "component---src-pages-management-writing-service-html-index-jsx": () => import("./../../../src/pages/management-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-management-writing-service-html-index-jsx" */),
  "component---src-pages-marketing-writing-service-html-index-jsx": () => import("./../../../src/pages/marketing-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-marketing-writing-service-html-index-jsx" */),
  "component---src-pages-masters-writing-service-html-index-jsx": () => import("./../../../src/pages/masters-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-masters-writing-service-html-index-jsx" */),
  "component---src-pages-mba-writing-service-html-index-jsx": () => import("./../../../src/pages/mba-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-mba-writing-service-html-index-jsx" */),
  "component---src-pages-money-back-guarantee-html-index-jsx": () => import("./../../../src/pages/money-back-guarantee.html/index.jsx" /* webpackChunkName: "component---src-pages-money-back-guarantee-html-index-jsx" */),
  "component---src-pages-narrative-writing-service-html-index-jsx": () => import("./../../../src/pages/narrative-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-narrative-writing-service-html-index-jsx" */),
  "component---src-pages-nursing-writing-service-html-index-jsx": () => import("./../../../src/pages/nursing-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-nursing-writing-service-html-index-jsx" */),
  "component---src-pages-our-samples-html-index-jsx": () => import("./../../../src/pages/our-samples.html/index.jsx" /* webpackChunkName: "component---src-pages-our-samples-html-index-jsx" */),
  "component---src-pages-pay-for-writing-html-index-jsx": () => import("./../../../src/pages/pay-for-writing.html/index.jsx" /* webpackChunkName: "component---src-pages-pay-for-writing-html-index-jsx" */),
  "component---src-pages-payment-index-jsx": () => import("./../../../src/pages/payment/index.jsx" /* webpackChunkName: "component---src-pages-payment-index-jsx" */),
  "component---src-pages-personal-statement-writing-service-html-index-jsx": () => import("./../../../src/pages/personal-statement-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-personal-statement-writing-service-html-index-jsx" */),
  "component---src-pages-phd-writing-service-html-index-jsx": () => import("./../../../src/pages/phd-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-phd-writing-service-html-index-jsx" */),
  "component---src-pages-philosophy-writing-service-html-index-jsx": () => import("./../../../src/pages/philosophy-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-philosophy-writing-service-html-index-jsx" */),
  "component---src-pages-plagiarism-free-guarantee-html-index-jsx": () => import("./../../../src/pages/plagiarism-free-guarantee.html/index.jsx" /* webpackChunkName: "component---src-pages-plagiarism-free-guarantee-html-index-jsx" */),
  "component---src-pages-plagiarism-free-writing-service-html-index-jsx": () => import("./../../../src/pages/plagiarism-free-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-plagiarism-free-writing-service-html-index-jsx" */),
  "component---src-pages-political-science-writing-service-html-index-jsx": () => import("./../../../src/pages/political-science-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-political-science-writing-service-html-index-jsx" */),
  "component---src-pages-pricing-html-index-jsx": () => import("./../../../src/pages/pricing.html/index.jsx" /* webpackChunkName: "component---src-pages-pricing-html-index-jsx" */),
  "component---src-pages-privacy-policy-html-index-jsx": () => import("./../../../src/pages/privacy-policy.html/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-html-index-jsx" */),
  "component---src-pages-private-writing-service-html-index-jsx": () => import("./../../../src/pages/private-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-private-writing-service-html-index-jsx" */),
  "component---src-pages-professional-writers-html-index-jsx": () => import("./../../../src/pages/professional-writers.html/index.jsx" /* webpackChunkName: "component---src-pages-professional-writers-html-index-jsx" */),
  "component---src-pages-psychology-writing-service-html-index-jsx": () => import("./../../../src/pages/psychology-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-psychology-writing-service-html-index-jsx" */),
  "component---src-pages-quality-assurance-html-index-jsx": () => import("./../../../src/pages/quality-assurance.html/index.jsx" /* webpackChunkName: "component---src-pages-quality-assurance-html-index-jsx" */),
  "component---src-pages-revisions-policy-html-index-jsx": () => import("./../../../src/pages/revisions-policy.html/index.jsx" /* webpackChunkName: "component---src-pages-revisions-policy-html-index-jsx" */),
  "component---src-pages-social-responsibility-notice-html-index-jsx": () => import("./../../../src/pages/social-responsibility-notice.html/index.jsx" /* webpackChunkName: "component---src-pages-social-responsibility-notice-html-index-jsx" */),
  "component---src-pages-sociology-writing-service-html-index-jsx": () => import("./../../../src/pages/sociology-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-sociology-writing-service-html-index-jsx" */),
  "component---src-pages-terms-conditions-html-index-jsx": () => import("./../../../src/pages/terms-conditions.html/index.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-html-index-jsx" */),
  "component---src-pages-testimonials-html-index-jsx": () => import("./../../../src/pages/testimonials.html/index.jsx" /* webpackChunkName: "component---src-pages-testimonials-html-index-jsx" */),
  "component---src-pages-undergraduate-writing-service-html-index-jsx": () => import("./../../../src/pages/undergraduate-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-undergraduate-writing-service-html-index-jsx" */),
  "component---src-pages-urgent-writing-service-html-index-jsx": () => import("./../../../src/pages/urgent-writing-service.html/index.jsx" /* webpackChunkName: "component---src-pages-urgent-writing-service-html-index-jsx" */),
  "component---src-pages-write-for-me-html-index-jsx": () => import("./../../../src/pages/write-for-me.html/index.jsx" /* webpackChunkName: "component---src-pages-write-for-me-html-index-jsx" */)
}

